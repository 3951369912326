































































































import { Component, Vue } from 'vue-property-decorator';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';

@Component({
  components: {},
  metaInfo: metaInfo({
    title: 'Podnikejte s námi | Kakadoo',
    url: buildUrl('/stante-se-partnerem'),
    description: 'Získejte svou vlastní bezkontaktní půjčovnu dodávek jako franšíza s Kakadoo.cz!'
  })
})
export default class Partner extends Vue {}
